import React, {useState} from "react";
import Layout from "../components/layout/layout";
import SEO from "../components/seo";
import {graphql, Link} from "gatsby";
import Grid, {Col, Row} from "../components/grid/grid";
import Img from "gatsby-image";
import styled from "styled-components";
import SwipeableViews from 'react-swipeable-views';

const Categories = styled.span`
  font-weight: 500;
`

const StyledCol = styled(Col)`
  margin-bottom: 30px;
  margin-top: 50px;
`

type CategorieProps = {
  isActive: boolean
}

const Categorie = styled.span<CategorieProps>`
  margin: 0 15px;
  padding-bottom: 10px;
  cursor: pointer;
  ${({ isActive}) => isActive ? 'border-bottom: 3px solid;' : '' }
`

const StyledLink = styled(Link)`
  font-weight: 300;
  color: black;
  text-decoration: none;
`

const StyledImage = styled(Img)`
  margin-bottom: 15px;
`

const ProjectCol = styled(Col)`
  margin-bottom: 30px;
`

const StyledGrid = styled(Grid)`
    @media (min-width: 960px) {
      padding-left: 0;
      padding-right: 0;
    }
`

const CATEGORIES = ['All', 'Private', 'Public'];

export default function Projects({data}: any) {
  const [filter, setFilter] = useState(0)
  return (
    <Layout>
      <SEO title="Projects"/>
      <StyledGrid>
        <Row>
          <StyledCol>
            <Categories>Project Categories:</Categories>
            {CATEGORIES.map((categorie, index) =>
              <Categorie key={categorie} isActive={filter === index} onClick={() => setFilter(index)}>
                {categorie}
              </Categorie>)
            }
          </StyledCol>
        </Row>
        <Row>
          <SwipeableViews index={filter} onChangeIndex={index => setFilter(index)}>
            {CATEGORIES.map((categorie, index) => {
              const projects = data.allContentfulProjects.nodes.filter(
                (project: any) => project.category.toLowerCase() === categorie.toLowerCase() || index === 0
              )
              return (<div key={categorie}>{
                projects.map((post: any) => {
                  return (
                    <ProjectCol small={12} medium={6} large={12} key={post.projectName}>
                      <StyledLink
                        to={`/projects/${post.slug}/`}
                      >
                        {post?.mainImage?.fluid && <StyledImage fluid={{...post?.mainImage?.fluid, aspectRatio: 4 / 3 }}/>}
                        <span>{`${post.projectName}, `}</span>
                        <span>{`${post.projectArea} sq.m`}</span>
                      </StyledLink>
                    </ProjectCol>
                  )
                })
              }</div>)
            })}
          </SwipeableViews>
        </Row>
      </StyledGrid>
    </Layout>
  )
}

export const query = graphql`{
  allContentfulProjects(sort: {fields: order}) {
    nodes {
      mainImage {
        fluid(maxWidth: 600, quality: 90) {
            ...GatsbyContentfulFluid_withWebp
        }
      }
      projectName
      projectArea
      slug
      category
    }
  }
}
`
